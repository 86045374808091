





import { Component, Vue } from 'vue-property-decorator';
import VueRouter, {Route} from 'vue-router';
import SimpleLayouts from '@/layouts/SimpleLayouts.vue';
import store from '@/store';

Component.registerHooks([
  'beforeRouteEnter',
]);


@Component({
  components: {
    SimpleLayouts,
  },
})
export default class Logout extends Vue {
  private status: number = 200;

  private async mounted() {
    if (this.status === 200) {
      this.$router.replace('/');
    }
  }
  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    // const res = await store.dispatch('auth/loginStatus')
    try {
      const res = await store.dispatch('auth/logoutAuth');
      next((vm: {status: number}) => {
        vm.status = 200;
      });
    } catch (e) {
      next((vm: {status: number}) => {
        vm.status = e.status;
      });
    }
  }
}

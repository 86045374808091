



import { VeeValidateConfig } from 'vee-validate/dist/types/config';
import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter, {Route} from 'vue-router';
import store from '../../store';
import VeeValidate from '../../plugins/vee-validate';
import Error from '../../components/contents/Error.vue';
import { LoginRequest } from '../../../types/typescript-axios/api';
import { GetLoginResponse } from '../../../types/typescript-axios/api';
import {AxiosResponse, AxiosError} from 'axios';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

@Component({
  mixins: [ VeeValidate ],
  components: {
    Error,
  },
})
export default class Login extends Vue {
  private status: number = 200;
  private errorCode: number|null = null;
  private errorMessage: boolean = false;
  private from: string = '/';
  private mail: string = '';
  private password: string = '';
  get signupUrl(): string {
    return process.env.VUE_APP_PATH_SIGNUP;
  }
  get loginForgetPasswordUrl(): string {
    return process.env.VUE_APP_PATH_LOGIN_FORGET_PASSWORD;
  }
  private longlogin: boolean = false;

  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }
  get loginRedirect(): string {
    const query: any = this.$route.query;
    if (typeof query.redirect !== 'undefined') {
      return String(this.$route.query.redirect);
    } else {
      return '/';
    }
  }
  private back() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }
  private async login() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result) {
        const param: LoginRequest = {
          password: this.password,
          username: this.mail,
          longlogin: this.longlogin,
        };
        await store.dispatch('auth/loginAuth', param)
        .then((response: any) => {
          this.$router.replace({ path: this.loginRedirect });
        }, (error: any) => {
          if (error.status === 401 && error.data.errors.code === 10) {
            this.$router.push({ path: '/login/error/' });
          } else {
            this.errorMessage = true;
            this.status = error.status;
            this.errorCode = error.data.errors.code;
          }
        });
      } else {
        this.errorMessage = true;
        window.scrollTo({
          // top: this.contentPosition.top,
          top: 0,
          behavior: 'smooth',
        });
      }
    });
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    let fromPath: string|Array<(string|null)> = from.fullPath;
    if (typeof from.query.from !== 'undefined') {
      fromPath = from.query.from;
    }
    const res: AxiosResponse<GetLoginResponse> = await store.dispatch('auth/loginStatus');
    if (res.data.success) {
      next({ path: '/' });
    } else {
      next((vm: {
        from: string|Array<(string|null)>,
      }) => {
        vm.from = fromPath;
      });
    }
  }
}
